<template>
  <div>
    <b-modal
      ref="modalDataPlanos"
      :title="$t('Planos de comunicação')"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="true"
      :ok-title="$t('Fechar')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="confirmSaveModal"
    >
      <b-row>
        <b-col
          v-for="(row, key) in planos"
          :key="`plano-${key}`"
          md="4"
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <div class="item-img text-center pt-0">
              <b-link>
                <b-img
                  fluid
                  class="card-img-top"
                  :src="row.imagem"
                />
              </b-link>
            </div>

          </b-card>
          <div class="form-group-compose text-center compose-btn">
            <b-button
              v-if="showButtonView === true"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              block
              class="btn-cart"
            >
              <span>{{ $t('Visualizar') }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              class="btn-cart"
              @click="savePlanoComunicacao(row.id)"
            >
              <span>{{ $t('Selecionar') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BCard, BLink, BImg, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCard,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
    planos: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      showButtonView: false,
    }
  },
  computed: {
    isDark() {
      return this.skin.value === 'dark'
    },
  },
  methods: {
    showModal() {
      return new Promise((resolve, reject) => {
        this.$refs.modalDataPlanos.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hideModal() {
      this.$refs.modalDataPlanos.hide()
    },
    async confirmSaveModal(evt) {
      evt.preventDefault()
      this.resolvePromise(true)
    },
    async savePlanoComunicacao(vlModelo) {
      this.$root.$emit('app::loading', true)
      await this.$store.dispatch('listings_form/saveModeloPlanoComunicacao', { idModelo: vlModelo }).then(async result => {
        this.$root.$emit('app::loading', false)
        if (result.error !== 1) {
          this.showMsgSuccessRequest({ message: result.msg })
          this.resolvePromise(true)
        }
      }).catch(error => {
        this.$root.$emit('app::loading', false)
        throw error
      })
    },
  },
  setup() {
    const { skin } = useAppConfig()

    return {
      skin,
    }
  },
}
</script>
